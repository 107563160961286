<template>
  <div id="app">
    <nav class="uk-navbar-container">
      <div class="uk-container">
        <div uk-navbar>
          <div class="uk-navbar-center">
            <ul class="uk-navbar-nav" uk-grid>
              <!--<router-link exact tag="li" to="/">
                <a>О сервисе</a>
              </router-link>-->
              <router-link
                v-slot="{ navigate, isActive }"
                to="/group"
                custom
                active-class="uk-active"
              >
                <li
                  :class="[
                    'uk-width-1-3',
                    'uk-width-auto@m',
                    { 'uk-active': isActive },
                  ]"
                  @click="navigate"
                  @keypress.enter="navigate"
                >
                  <a>Поиск по&nbsp;группе</a>
                </li>
              </router-link>
              <router-link
                v-slot="{ navigate, isActive }"
                to="/hashtag"
                custom
                active-class="uk-active"
              >
                <li
                  :class="[
                    'uk-width-1-3',
                    'uk-width-auto@m',
                    { 'uk-active': isActive },
                  ]"
                  @click="navigate"
                  @keypress.enter="navigate"
                >
                  <a>Поиск по&nbsp;хештегу</a>
                </li>
              </router-link>
              <!--<router-link tag="li" to="/map">
                <a>Поиск хештегов на карте</a>
              </router-link>-->
              <router-link
                v-slot="{ navigate, isActive }"
                to="/user"
                custom
                active-class="uk-active"
              >
                <li
                  :class="[
                    'uk-width-1-3',
                    'uk-width-auto@m',
                    { 'uk-active': isActive },
                  ]"
                  @click="navigate"
                  @keypress.enter="navigate"
                >
                  <a>Поиск по&nbsp;id&nbsp;котика</a>
                </li>
              </router-link>
              <!--              <router-link-->
              <!--                tag="li"-->
              <!--                to="/donations"-->
              <!--                class="uk-width-1-2 uk-width-auto@m"-->
              <!--              >-->
              <!--                <a>Помочь рублем</a>-->
              <!--              </router-link>-->
              <router-link
                v-slot="{ navigate, isActive }"
                to="/contacts"
                custom
                active-class="uk-active"
              >
                <li
                  :class="[
                    'uk-width-1-3',
                    'uk-width-auto@m',
                    { 'uk-active': isActive },
                  ]"
                  @click="navigate"
                  @keypress.enter="navigate"
                >
                  <a>Обратная связь</a>
                </li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="uk-container uk-padding-small">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        if (typeof this.$metrika !== "undefined")
          this.$metrika.hit(to.fullPath);

        if (from.name !== to.name)
          if (from.meta.category === "search") {
            this.$store.dispatch("search/clearPosts");
          }
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/style";

body {
  overflow-y: scroll;
  min-height: 100vh;
}

.uk-navbar-nav li {
  padding: 0 !important;
  margin: 0 !important;

  a {
    min-height: 60px;
  }
}
</style>
