let addressApi;
if (process.env.NODE_ENV === "development") {
  addressApi = `${location.protocol}//${location.hostname}:8081/api/`;
} else {
  addressApi = `${location.protocol}//${location.hostname}/api/`;
}

const state = {
  type: null,
  query: "",
  settings: {},
  groups: [],
  hashtags: [],
  users: [],
  posts: [],
  postsPage: 1,
};
const getters = {};
const actions = {
  getGroups: ({ commit }, { action = "get", group = "" }) => {
    return fetch(`${addressApi}groups/search/${group}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        return commit("setGroups", { action: action, data: response });
      });
  },
  getHashtags: ({ commit }, { action = "get", hashtag = "" }) => {
    return fetch(`${addressApi}hashtags/search/${hashtag}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        commit("setHashtags", { action: action, data: response });
      });
  },
  getUsers: ({ commit }, { action = "get", user = "" }) => {
    if (user === "") {
      return;
    }
    return fetch(`${addressApi}users/search/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        commit("setUsers", { action: action, data: response });
      });
  },
  setQuery: async ({ commit }, { type, query }) => {
    await commit("setSearchType", type);
    await commit("setQuery", query);
  },
  setSettings: ({ commit }, settings) => {
    return commit("setSettings", settings);
  },
  getPosts: ({ state, commit }, { action = "get", limit = 10 }) => {
    let query = {
      page: state.postsPage,
      limit: limit,
    };
    const lgbt = state.settings.lgbt?.value || 0;
    if (lgbt < 2) {
      query["lgbt"] = lgbt;
    }
    const queryStr = Object.keys(query)
      .map((param) => `${param}=${query[param]}`)
      .join("&");

    return fetch(
      `${addressApi}profiles/${state.type}/${state.query}/?${queryStr}`,
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        commit("setPosts", { action: action, data: response });
      });
  },
  clearGroups: ({ commit }) => {
    commit("clearGroups");
  },
  clearHashtags: ({ commit }) => {
    commit("clearHashtags");
  },
  clearPosts: ({ commit }) => {
    commit("clearPosts");
  },
};
const mutations = {
  setGroups(state, payload) {
    switch (payload.action) {
      case "get":
        state.groups = payload.data;
        break;
      case "add":
        state.groups = state.groups.concat(payload.data);
        break;
    }
  },
  setHashtags(state, payload) {
    switch (payload.action) {
      case "get":
        state.hashtags = payload.data;
        break;
      case "add":
        state.hashtags = state.hashtags.concat(payload.data);
        break;
    }
  },
  setUsers(state, payload) {
    switch (payload.action) {
      case "get":
        state.users = payload.data;
        break;
      case "add":
        state.users = state.users.concat(payload.data);
        break;
    }
  },
  setSearchType(state, payload) {
    state.type = payload;
  },
  setQuery(state, payload) {
    state.query = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  },
  setPosts(state, payload) {
    switch (payload.action) {
      case "get":
        state.postsPage = 1;
        state.posts = payload.data;
        break;
      case "add":
        state.postsPage++;
        state.posts = state.posts.concat(payload.data);
        break;
    }
  },
  clearGroups(state) {
    state.groups = [];
  },
  clearHashtags(state) {
    state.hashtags = [];
  },
  clearPosts(state) {
    state.posts = [];
    state.postsPage = 1;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
