import { createStore } from "vuex";

import search from "./modules/search";
import form from "./modules/form";

export default new createStore({
  modules: {
    search,
    form,
  },
});
