import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./modules/router";
import store from "./modules/store";
import "./modules/uikit";
// import "./modules/yandexmaps";
import VueGTag from "vue-gtag";
import Storage from "vue-ls";
import VueYandexMetrika from "vue3-yandex-metrika";
// import VueHead from "vue-head";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faVk, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueAxios from "vue-axios";
import axios from "axios";

library.add(faVk, faTelegramPlane, faAt);

const app = createApp({
  render: () => h(App),
});

app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);

app.use(store);
app.use(router);

// app.use(VueHead);

app.use(Storage, {
  namespace: "fk__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
});

app.use(VueGTag, {
  id: "UA-133280374-1",
  router,
});

app.use(VueYandexMetrika, {
  id: 52082784,
  env: process.env.NODE_ENV,
  router,
  options: {
    clickmap: true,
    trackLinks: true,
    webvisor: true,
    trackHash: true,
    accurateTrackBounce: true,
  },
});

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.mount("#app");
