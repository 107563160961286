// let addressApi;
// if (process.env.NODE_ENV === "development") {
//   addressApi = `${location.protocol}//${location.hostname}/api/`;
// } else {
//   addressApi = `${location.protocol}//${location.hostname}/api/`;
// }

const state = {
  response: {},
};
const getters = {
  getResponse: (state) => state.response,
};
const actions = {
  // sendContactForm: ({ state, commit }, payload) => {
  //   state.response = {};
  //   return this.axios
  //     .post(`${addressApi}form/contactform/`, payload)
  //     .then((response) => {
  //       commit("sendContactForm", { data: response.data });
  //     });
  // },
};
const mutations = {
  // sendContactForm(state, payload) {
  //   state.response = payload.data;
  // },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
