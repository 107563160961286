import { createRouter, createWebHistory } from "vue-router";
import { searchType } from "@/types";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "hashtagSearch" },
  },
  {
    path: "/hashtag",
    name: "hashtagSearch",
    component: () => import("@/views/HashtagSearch.vue"),
    meta: { type: searchType.hashtag, category: "search" },
  },
  {
    path: "/hashtag/:query",
    name: "definiteHashtagSearch",
    component: () => import("@/views/HashtagSearch.vue"),
    meta: { type: searchType.hashtag, category: "search" },
  },
  {
    path: "/group",
    name: "groupSearch",
    component: () => import("@/views/GroupSearch.vue"),
    meta: { type: searchType.group, category: "search" },
  },
  {
    path: "/group/:query",
    name: "definiteGroupSearch",
    component: () => import("@/views/GroupSearch.vue"),
    meta: { type: searchType.group, category: "search" },
  },
  {
    path: "/user",
    name: "userSearch",
    component: () => import("@/views/UserSearch.vue"),
    meta: { type: searchType.user, category: "search" },
  },
  {
    path: "/user/:query",
    name: "definiteUserSearch",
    component: () => import("@/views/UserSearch.vue"),
    meta: { type: searchType.user, category: "search" },
  },
  {
    path: "/map",
    name: "hashtagMap",
    component: () => import("@/views/HashtagMap.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/Contacts.vue"),
  },
  // {
  //   path: "/donations",
  //   name: "donations",
  //   component: () => import("@/views/Donations.vue")
  // }
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
